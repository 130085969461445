export default {
    "wgr": {
        "search": "Search",
        "private_group:": "Приватная",
        "work_groups": "Working groups",
        "work-groups": "Working groups",
        "about_me": "About us",
        "status": "Status",
        "closed": "Closed",
        "public": "Public",
        "date_create": "date of establishment",
        "date_begin": "start Time",
        "image_not_found": "No images",
        "add_image": "Add an image",
        "gallery": "Gallery",
        "remove_event": "Delete event",
        "events": "Events",
        "social_links": "social network",
        "type": "Type",
        "edit": "Edit",
        "news_not_found": "News",
        "no_partisipants_group": "You are not a member of the group",
        "invite_participants": "invite to party",
        "participants": "Participants",
        "add_participants": "Add participants",
        "create_group": "Create a group",
        "title_group": "group Name",
        "description": "Description",
        "group_type": "group Type",
        "add_soc_link": "Add social network",
        "avatar_group": "picture group",
        "avatar_project": "Avatar of the project",
        "create": "Create",
        "upload_file": "Upload file",
        "create_post": "Create a publication",
        "title_news": "News title",
        "image": "Picture",
        "type_soc_link": "social networking",
        "place": "Place",
        "invite": "Invite",
        "make_appointment": "book an appointment",
        "more": "More",
        "participantss": "participants",
        "director": "Founder",
        "tasks_complete": "Completed tasks",
        "no_data": "No data",
        "today_no_events": "no events Today",
        "link": "Link",
        "information_edited": "Info changed",
        "group_created": "Group created successfully",
        "fill_all_fields": "Fill all fields",
        "invalid_link": "Invalid link",
        "join_group": "Join group",
        "invite_group": "invite to group",
        "cancel": "Cancel",
        "fill_FIO": "Enter full name",
        "news": "news",
        "event": "Event",
        "task": "Task",
        "role": "Role",
        "group": "Group",
        "open": "Open",
        "projects": "Projects",
        "create_project": "Create project",
        "project_not": "Project no",
        "group_not": "no groups",
        "title_project": "project Name",
        "deadline_project": "the Deadline",
        "finished_project": "Complete project",
        "resume_project": "Continue project",
        "time": "Time",
        "time_is_required": "you Need to specify the time",
        "group-and-project": "Groups and projects",
        "group&project": "Groups and projects",
        "workgroups": "Working groups",
        "project_created": "Project created",
        "project_finished": "Project completed",
        "enter_the_conference": "Sign in conference",
        "meetings": "Conference",
        "invite_link": "Link to the prompt",
        "participant": "participant",
        "participant2": "participant",
        "participant3": "participants",
        "event_name": "event Name",
        "periud_time": "time",
        "you_partisipants": "You are a member of the group",
        "you_not_member_group": "You are no longer a member of the group",
        "successful": "Success",
        "news_created": "News created by",
        "member_delete": "Member-deleted",
        "date_validation_min_max": "start date must be before end date",
        "event_created": "Event created",
        "member_is_moderator": "Member appointed by the moderator",
        "exit": "Published",
        "load_more": "Load more",
        "error": "Error",
        "update": "Update",
        "delete_image": "Delete image",
        "yes": "Yes",
        "no": " No",
        "field_required": "Required"
    }
}