export default {
    "files": {
        "files": "Файлы",
        "info": "Информация",
        "delete": "Удалить",
        "pin": "Прикрепить",
        "open_in_new_tab": "Открыть в новой вкладке",
        "create_task": "Создать задачу",
        "share": "Поделиться",
        "edit": "Изменить",
        "archive_is_already_working": "Архив уже подготавливается",
        "archive_is_working": "Архив подготавливается",
        "error": "Ошибка",
        "download": "Скачать",
        "download_as_zip": "Скачать как ZIP",
        "name": "Имя",
        "description": "Описание",
        "size": "Размер",
        "extension": "Расширение",
        "file_type": "Тип файла",
        "cancel": "Отмена",
        "save": "Сохранить",
        "restore": "Восстановить"
    }
}